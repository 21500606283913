<template>
	<div class="measure">
		<frame :top1="top1" :top2="top2">
			<lists :titles='titles' :contentC='datas' @add='refresh()' @del='refresh()'></lists>
			<el-pagination
			  @size-change="handleSizeChange"
			  @current-change="handleCurrentChange"
			  :current-page="currentPage4"
			  :page-sizes="[10,15]"
			  :page-size="pageSize"
			  layout="total, sizes, prev, pager, next, jumper"
			  :total="titlePage"
			>
			</el-pagination>
		</frame>
	</div>
</template>

<script>
	import lists from './componMeasure/MeasureList.vue'
	import frame from '../public/Frame.vue';
	export default {
		name: 'measure',
		data() {
			return {
				top1: '4-8',
				top2: ['4'],
				titles: ['计量单位代码', '计量单位名称', '默认新增商品单位', '操作'],
				datas: null,
				modal1: false,
				currentPage4: 1,
				titlePage:1,
				currentpage: 1,
				set:1,
				pageSize:15,
				formValidate: {
					name: '',
					city: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				typeFlag: null,
				list:null,
				uploadingUrl: '',
				imageUrl: '',
			}
		},
		components: {
			frame,
			lists
		},
		created() {
			let id = this.$storage.getLocal('type');
			let token = this.$storage.getLocal('token');
			this.$request.meteringListData({token,id,storeid:'',page:1,limit:15}).then(res => {
				if (res.code == 0) {
					this.datas = res.data;
					this.titlePage=res.count
				}
			})
			  console.log(this.datas)
		},
		methods:{
			handleSizeChange(val) {
				  console.log(`每页 ${val} 条`);
				  
				   this.pageSize = val;
			let id = this.$storage.getLocal('type');
			let token = this.$storage.getLocal('token');
			this.$request.meteringListData({token,id,storeid:'',page:1,limit:this.pageSize}).then(res => {
				if (res.code == 0) {
					this.datas = res.data;
				}
			})
				},
				
				handleCurrentChange(val) {
		let id = this.$storage.getLocal('type');
		let token = this.$storage.getLocal('token');
		this.$request.meteringListData({token,id,storeid:'',page:val,limit:this.pageSize}).then(res => {
			if (res.code == 0) {
				this.datas = res.data;
			}
		})
			},
			refresh(){
				let id = this.$storage.getLocal('type');
				let token = this.$storage.getLocal('token');
				this.$request.meteringListData({token,id,storeid:'',page:1,limit:15}).then(res => {
					if (res.code == 0) {
						this.datas = res.data;
						
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
