<template>
	<div class="list">
		<el-dialog title="详情" :visible.sync="dialogVisible" width="30%">
			<span>这是一段信息</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">商品列表</div>
				<div class="title">
					<div class="title">
					<!-- 	<div class="listcont">
							<div class="shuai" v-if="typeFlag==1">
							<div class="selname">商家类型:</div>
							<Select  placeholder="请选择" class="sel">
													
							<Option  :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shopType(item.storeid)">{{item.s_name}}</Option>
							 </Select>
							</div>	
							<div class="shuai" v-if="typeFlag==1||typeFlag==2">
							<div class="selname" style="margin-left: -80px;">店铺类型:</div>
							<Select  placeholder="请选择" class="sel">
													
							<Option  :value="item.storeid" v-for='item,index in selectp' :key='index' @click.native="storeidType(item.storeid)">{{item.s_name}}</Option>
							 </Select>
							</div>	
							<div class="input">
								<Button type="success" style="margin-left: 20px;" @click='search(xstoreid)'>搜索</Button>
							</div>
						</div> -->
						<div class="add">
							<el-button type="warning" style="margin-left: -200px;background-color: red;color: white;" plain @click='addType()'>添加</el-button>
						</div>
					</div>
				</div>
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2'>
									<td>{{item2.gmcode}}</td>
									<td>{{item2.gmname}}</td>
									<td @click="start(item2.gmid,item2.gmtype)">
										<el-switch v-model="item2.gmtype==1?true:false" active-color="#13ce66" inactive-color="#ff4949" :disabled='item2.gmtype==1?true:false'>
										</el-switch>
									</td>
									<td class="oper">
										<div class="com" @click="compile(item2.gmid,item2.gmname,item2.gmcode,item2.gmtype,item2.storeid)">编辑</div>
										<div class="del" @click="del(item2.gmid)">删除</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
		<Modal v-model="modal1" title="添加类型" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
				<FormItem label="单位代码" prop="city">
					<Input v-model="formValidate.city" placeholder="请输入单位名称"></Input>
				</FormItem>
				<FormItem label="单位名称" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入单位代码"></Input>
				</FormItem>
				<FormItem label="默认新增商品单位" prop="audit">
					<RadioGroup v-model="formValidate.audit">
						<Radio label="是"></Radio>
						<Radio label="否"></Radio>
					</RadioGroup>
				</FormItem>
				<FormItem label="所属店铺" prop="storeid" v-if='typeFlag!=3'>
					<Select v-model="formValidate.storeid" placeholder="请选择">
						<Option :value="item.storeid" v-for='item,index in list' :key='index'>{{item.s_name}}</Option>
					</Select>
				</FormItem>
			</Form>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			}
		},
		data() {
			return {
				social: [],
				dialogVisible: false,
				uploadingUrl1: '',
				modal1: false,
				xstoreid:'',
				storeid:'',
				selectp:'',
				selects:'',
				formValidate: {
					name: '',
					city: '',
					audit: '是',
					storeid: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入单位代码',
						trigger: 'blur'
					}],
					city: [{
						required: true,
						message: '请输入单位名称',
						trigger: 'blur'
					}]
				},
				typeFlag: null,
				list:null,
				id: false,
			}
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.typeFlag = this.$storage.getLocal('type');
			
			this.$request.getNextSelectList({token}).then(res => {
				if (res.code == 0) {
					this.list = res.data;
				}
			})
			
			if(this.typeFlag=='1')
			{
				this.$request.getMerchantData({token}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selects = r.data;
						console.log(r)
				
					}
				})
			}else if(this.typeFlag=='2')
			{
				this.$request.getStoreDataList({token,pstoreid:'',login_type:this.$storage.getLocal('type')}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selectp = r.data;
						console.log(r)
					}
				})
			}
			
		},
		components: {

		},
		methods: {
			
			start(id, start) {
				let token = this.$storage.getLocal('token');
				this.$request.upmeteringType({
					token,
					id,
					gmtype: start==2 ? 1 : 2,
				}).then(res => {
					if (res.code == 0) {
						this.$notify({
							title: '成功',
							message: '成功',
							type: 'success'
						});
						this.$emit("del", 'ok');
					} else {
						this.$notify({
							title: '警告',
							message: '已经是默认',
							type: 'warning'
						})
					}
				})
			},
			shopType(value){
				console.log(111)
				let token = this.$storage.getLocal('token');
				this.$request.getStoreDataList({token,pstoreid:value,login_type:this.$storage.getLocal('type')}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selectp = r.data;
						console.log(r)
					}
				})
			},
			storeidType(value)
			{
				this.xstoreid=value
			},
		search(value)
		{
			let token = this.$storage.getLocal('token');
			let id = this.$storage.getLocal('type');
		this.$request.meteringListData({token,id,storeid:value,limit:15,page:1}).then(res => {
			if (res.code == 0) {
				this.datas = res.data;
			}
		})
		},
			addType() {
				this.modal1 = true
			},
			del(id) { //删除
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.delmeteringData({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						}else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: res.msg,
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			examine(id) { //查看
				this.dialogVisible = true
			},
			log(id) { //日志
				console.log(id)
			},
			compile(id,name,code,type,storeid) { //编辑
				this.modal1 = true;
				this.formValidate.name = name;
				this.formValidate.city = code;
				this.formValidate.audit = type==1?'是':'否';
				this.id = id;
				this.storeid=storeid
			},
			cancel() {
				this.$Message.info('取消添加');
			},
			ok() {
				let token = this.$storage.getLocal('token');
				let id = this.$storage.getLocal('type');
				if(this.id){//编辑
					this.$refs['formValidate'].validate((valid) => {
						if (valid) {
							this.$request.upmeteringData({
								token,
								name: this.formValidate.name,
								id: this.id,
								type: this.formValidate.audit == '是'?1:2,
								gmcode: this.formValidate.city
							}).then(res => {
								if (res.code == 0) {
									this.$Message.success('编辑成功!');
									this.$emit("del", 'ok');
									this.$refs['formValidate'].resetFields();
								}else{
									this.$notify({
										title: '失败',
										message: res.msg,
										type: 'warning'
									});
								}
							})
						} else {
							this.$Message.error('添加失败请输入名称！！！');
						}
					})
				}else{//添加
					let type = this.$storage.getLocal('type')
					this.$refs['formValidate'].validate((valid) => {
						if (valid) {
							this.$request.meteringAddData({
								token,
								name: this.formValidate.name,
								show: this.formValidate.audit == '是'?1:2,
								id: this.formValidate.storeid,
								type,
								gmcode: this.formValidate.city
							}).then(res => {
								if (res.code == 0) {
									this.$Message.success('添加成功!');
									this.$emit("del", 'ok');
									this.$refs['formValidate'].resetFields();
								}
							})
						} else {
							this.$Message.error('添加失败请输入名称！！！');
						}
					})
				}
				
			}
		}
	}
</script>

<style scoped="scoped">
	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	.img img{
		width: 20px;
	}
	.title {
		width: 88%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
	}
	.add {
		height: 50px;
	}
	
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 58px;
		height: 58px;
		line-height: 58px;
		text-align: center;
	}
	
	.avatar {
		width: 58px;
		height: 58px;
		display: block;
	}
	
	.avatar-uploader-icon {
		border: 1px solid #333333;
	}
	
	.avatar-uploader {
		text-align: center;
	}
	.listcont
	{
		display: flex;
		}
	.shuai
	{
		display: flex;
		/* border:1px solid black */
	}
	.selname
	{
		width: 90%;
	}
	.sel
	{
		margin-left: 10px;
		margin-top: 8px;
	}
</style>
